import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { CardHabilidades } from "./CardHabilidades";
import { ModalHabilidaes } from "./ModalHabilidaes";
import { HabilidadesContext } from "../../contexts/HabilidadesContext";
import { ModalVerTodos } from "./ModalVerTodos";
import { ModalActualizaTusEskills } from "./ModalActualizaTusEskills";
import { TabPanel, TabView } from "primereact/tabview";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Rating } from "primereact/rating";

const Habilidades = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [infoModal, setInfoModal] = useState("");
  const [isOpenModalVerTodos, setIsOpenModalVerTodos] = useState(false);
  const [datos, setDatos] = useState([]);
  const [tipoNovedad, setTipoNovedad] = useState(0);
  const [validateRecursosSkills, setValidateRecursosSkills] = useState([]);
  const navigate = useNavigate();
  const {
    selectSkills,
    getSkills,
    postSkills,
    getSkillsUser,
    skillUser,
    editSkill,
    setEditSkill,
    deleteSkills,
    updateSkills,
    getTecnologias,
    selectTecnologias,
    selectSubTecnologias,
    getCertificaciones,
    certificaciones,
    postCertificaciones,
    getCurriculum,
    curriculum,
    postCurriculum,
    getNivelEstudios,
    selectNivelEstudio,
    getNivelEstudioUser,
    nivelEstudioUser,
    getTecnologiasUser,
    tecnologiaUser,
    openModalActualizacion,
    setOpenModalActualizacion,
    postActualizacionDatos,
    getRecursosSkills,
    recursosSkills,
    postValidarRecursosSkills,
  } = useContext(HabilidadesContext);

  useEffect(() => {
    getSkills();
    getSkillsUser();
    getTecnologias();
    getCertificaciones();
    getCurriculum();
    getNivelEstudios();
    getNivelEstudioUser();
    getTecnologiasUser();
    getRecursosSkills();
  }, []);

  useEffect(() => {
    if (!recursosSkills.length) {
      setTipoNovedad(0);
    }
  }, [recursosSkills]);

  const handleValidate = (e, tecnologia, type, gerente) => {
    if (validateRecursosSkills.some((skill) => skill._id === tecnologia._id)) {
      setValidateRecursosSkills(
        validateRecursosSkills.map((skill) =>
          skill._id === tecnologia._id
            ? { ...skill, validatedAssessment: e.value, type: type }
            : skill
        )
      );
      return;
    }
    setValidateRecursosSkills([
      ...validateRecursosSkills,
      { _id: tecnologia._id, validatedAssessment: e.value, type, gerente },
    ]);
  };
  const handleGuardarSkills = (skills) => {
    const skillsIds = skills.map((skill) => ({
      idSkill: skill._id,
      assessment: skill.assessment || 1,
    }));
    postSkills(skillsIds);
    setIsOpenModal(false);
  };

  const handleGuardarcertificaciones = (certificate) => {
    postCertificaciones(certificate);
    setIsOpenModal(false);
  };

  const handleGuardarcurriculum = (curriculum) => {
    postCurriculum(curriculum);
    setIsOpenModal(false);
  };

  const handleEliminar = (skill) => {
    deleteSkills(skill);
    setIsOpenModal(false);
    setEditSkill(false);
  };

  const handleUpdate = (skills) => {
    updateSkills(skills);
    setIsOpenModal(false);
    setEditSkill(false);
  };

  const validatedValue = (id) => {
    if (validateRecursosSkills.some((skill) => skill._id === id)) {
      return validateRecursosSkills.find((skill) => skill._id === id)
        .validatedAssessment;
    }
    return 0;
  };

  return (
    <>
      <div style={{ marginLeft: "20px" }}>
        <Button
          label="Volver atrás"
          icon="pi pi-angle-left"
          className="button-volver"
          onClick={() => {
            navigate("/novedades");
          }}
        />
      </div>
      <div style={{ marginLeft: "33px", marginTop: "10px" }}>
        <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>
          Mis habilidades
        </h1>
        <p>
          Acá vas a poder ingresar todos los datos relacionados a tu perfil
          profesional
        </p>
      </div>
      <TabView
        activeIndex={tipoNovedad}
        onTabChange={(e) => {
          setTipoNovedad(e.index);
        }}
        style={recursosSkills.length ? { marginTop: "20px" } : {}}
        className={"card " + (!recursosSkills.length ? "hidenPanel" : "")}
      >
        <TabPanel className={"titulo_solapa "} header="Skills">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "15px",
              marginLeft: "20px",
            }}
          >
            <CardHabilidades
              icon={"psychology"}
              title={"Skills Complementarias"}
              description={"No tenés skills ingresadas"}
              info={
                "Acá vas a poder agregar Skills complementarias al rol que estás desempeñando"
              }
              cancel={setIsOpenModal}
              setInfoModal={setInfoModal}
              setDatos={setDatos}
              data={skillUser}
              setEditSkill={setEditSkill}
              open={setIsOpenModalVerTodos}
            />
            <CardHabilidades
              icon={"computer"}
              title={"Tecnologías"}
              description={"No tenés tecnologías ingresadas"}
              info={"Acá vas a poder agregar todas las tecnologías que manejás"}
              cancel={setIsOpenModal}
              setInfoModal={setInfoModal}
              setDatos={setDatos}
              data={tecnologiaUser}
              setEditSkill={setEditSkill}
              open={setIsOpenModalVerTodos}
            />
            <CardHabilidades
              icon={"workspace_premium"}
              title={"Certificaciones"}
              description={"No tenés certificaciones ingresadas"}
              info={"Acá vas a poder adjuntar todas tus certificaciones"}
              cancel={setIsOpenModal}
              setInfoModal={setInfoModal}
              setDatos={setDatos}
              data={certificaciones}
              setEditSkill={setEditSkill}
              open={setIsOpenModalVerTodos}
            />
            <CardHabilidades
              icon={"school"}
              title={"Nivel de estudios"}
              description={"No tenés estudios ingresados"}
              info={"Acá vas a poder informar tu nivel de estudios"}
              cancel={setIsOpenModal}
              setInfoModal={setInfoModal}
              setDatos={setDatos}
              data={nivelEstudioUser}
              setEditSkill={setEditSkill}
              open={setIsOpenModalVerTodos}
            />
            <CardHabilidades
              icon={"contact_page"}
              title={"Carga de cv"}
              description={"No tenés tu cv cargado"}
              info={"Acá vas a poder cargar tu cv"}
              cancel={setIsOpenModal}
              setInfoModal={setInfoModal}
              setDatos={setDatos}
              data={curriculum}
              setEditSkill={setEditSkill}
              open={setIsOpenModalVerTodos}
            />
          </div>
          <div
            style={{
              marginLeft: "20px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Link
              to="#"
              style={{ color: " #084bfa" }}
              onClick={(e) => {
                e.preventDefault();
                setIsOpenModal(true);
                setInfoModal("sugerencia de tecnología");
              }}
            >
              ¿Queres sugerir una nueva tecnología?
            </Link>
          </div>
        </TabPanel>
        <TabPanel className="titulo_solapa" header="Validar">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              marginTop: "20px",
              paddingBottom: "70px",
              position: "relative",
            }}
          >
            <Accordion
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {recursosSkills.map((recurso) => (
                <AccordionTab header={recurso.nombre + " " + recurso.apellido}>
                  {recurso.tecnologias.length > 0 && (
                    <ul>
                      <h2 style={{ borderBottom: "2px solid #a2a8af" }}>
                        Tecnologías
                      </h2>
                      {recurso.tecnologias.map((tecnologia) => (
                        <li className="validateItem">
                          <div className="skillContainer">
                            <p>{`${tecnologia.technology} ${
                              tecnologia?.subTechnology || ""
                            }`}</p>
                            <Rating
                              value={tecnologia.assessment || 3}
                              disabled
                              cancel={false}
                            />
                          </div>
                          <div className="skillContainer">
                            <p>Valida esta Skill</p>
                            <Rating
                              value={validatedValue(tecnologia._id)}
                              onChange={(e) =>
                                handleValidate(
                                  e,
                                  tecnologia,
                                  "tecnologia",
                                  recurso.gerente
                                )
                              }
                              cancel={false}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                  {recurso.skills.length > 0 && (
                    <ul>
                      <h2 style={{ borderBottom: "2px solid #a2a8af" }}>
                        Skills
                      </h2>
                      {recurso.skills.map((skill) => (
                        <li className="validateItem">
                          <div className="skillContainer">
                            <p>{skill.name}</p>
                            <Rating
                              value={skill.assessment || 3}
                              disabled
                              cancel={false}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flex: 1,

                              justifyContent: "space-between",
                            }}
                          >
                            <p>Valida esta Skill</p>
                            <Rating
                              value={validatedValue(skill._id)}
                              onChange={(e) =>
                                handleValidate(
                                  e,
                                  skill,
                                  "skill",
                                  recurso.gerente
                                )
                              }
                              cancel={false}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </AccordionTab>
              ))}
            </Accordion>
            <Button
              style={{ position: "absolute", bottom: 20, right: 0 }}
              className="button-modal-habilidades"
              onClick={() => {
                postValidarRecursosSkills(validateRecursosSkills);
              }}
              disabled={validateRecursosSkills.length === 0}
            >
              Guardar
            </Button>
          </div>
        </TabPanel>
      </TabView>

      {isOpenModal && (
        <ModalHabilidaes
          cancel={setIsOpenModal}
          infoModal={infoModal}
          skills={selectSkills}
          tecnologias={selectTecnologias}
          subTecnologias={selectSubTecnologias}
          datos={datos}
          guardarSkill={handleGuardarSkills}
          guardarCertificaciones={handleGuardarcertificaciones}
          guardarCurriculum={handleGuardarcurriculum}
          editSkill={editSkill}
          setEditSkill={setEditSkill}
          deleteSkills={handleEliminar}
          updateSkills={handleUpdate}
          nivelEstudios={selectNivelEstudio}
          open={setIsOpenModalVerTodos}
        />
      )}

      {isOpenModalVerTodos && (
        <ModalVerTodos
          open={setIsOpenModalVerTodos}
          infoModal={infoModal}
          datos={[]}
          setEditSkill={setEditSkill}
          cancel={setIsOpenModal}
        />
      )}

      {openModalActualizacion && (
        <ModalActualizaTusEskills
          open={setOpenModalActualizacion}
          postActualizacionDatos={postActualizacionDatos}
        />
      )}
    </>
  );
};

export default Habilidades;
